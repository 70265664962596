<template>
  <div>
    <div v-for="(game, index) in games" :key="game.ID" class="py-2 tour-link">
      <div class="d-flex">
        <div class="flex-shrink-1 align-self-center text-left game-time-block">
          <div class="font-weight-normal time-start-game text-white">
            {{ game.time }}
          </div>
          <div
              class="small game-online"
              :class="[
              game.state === 'live'
                ? dataSport.textClass
                : 'text-white-50 state-small'
            ]"
          >
            {{ game.state }}
          </div>
        </div>
        <div class="pl-2 w-100">
          <div class="d-flex justify-content-center align-items-center">
            <div class="text-right w-100">
              <div
                  class="font-weight-normal team-font-size small text-white"
                  v-text="
          $i18n.locale === 'ru'
            ? game.team_side_1_title
            : game.team_side_1_title_eng
        "
              />
            </div>
            <div class="px-3 game-score text-center">
              <!-- Проверяем, что team_number не равен 0 и определяем победителя -->
              <div v-if="game.team_number !== 0">
                <div class="position-relative" v-if="game.team_number === 1">
                  <div class="text-nowrap">
                    <span :class="[dataSport.textClass, { 'text-white': game.team_number !== 1 }]">{{ game.score_side1 }}</span>
                    <span class="separator text-white">-</span>
                    <span class="text-white">{{ game.score_side2 }}</span>
                  </div>
                </div>
                <div class="position-relative" v-else-if="game.team_number === 2">
                  <div class="text-nowrap">
                    <span class="text-white">{{ game.score_side1 }}</span>
                    <span class="separator text-white">-</span>
                    <span :class="[dataSport.textClass, { 'text-white': game.team_number !== 2 }]">{{ game.score_side2 }}</span>
                  </div>
                </div>
              </div>
              <div v-else>
                <div class="position-relative" v-if="game.score_side1 > game.score_side2">
                  <div class="text-nowrap">
                    <span :class="[dataSport.textClass]">{{ game.score_side1 }}</span>
                    <span class="separator text-white">-</span>
                    <span class="text-white">{{ game.score_side2 }}</span>
                  </div>
                </div>
                <div class="position-relative" v-else-if="game.score_side1 < game.score_side2">
                  <div class="text-nowrap">
                    <span class="text-white">{{ game.score_side1 }}</span>
                    <span class="separator text-white">-</span>
                    <span :class="[dataSport.textClass]">{{ game.score_side2 }}</span>
                  </div>
                </div>
                <div class="position-relative" v-else-if="game.score_side1 === game.score_side2 && game.state !== 'upcoming'">
                  <div class="text-nowrap">
                    <span class="text-white">{{ game.score_side1 }}</span>
                    <span class="separator text-white">-</span>
                    <span class="text-white">{{ game.score_side2 }}</span>
                  </div>
                </div>
                <div v-else>
                  <b class="separator text-white">-</b>
                </div>
              </div>
            </div>
            <div class="text-left w-100">
              <div
                  class="font-weight-normal team-font-size small text-white"
                  v-text="
          $i18n.locale === 'ru'
            ? game.team_side_2_title
            : game.team_side_2_title_eng
        "
              />
            </div>
            <!-- Контейнер для значка SVG и подсказки -->
            <div v-if="game.reason && game.reason > 0" class="tooltip-container">
              <span class="tooltip-icon">
                <img :src="svgPath" alt="info icon" />
              </span>
              <div class="tooltip-message">
                {{ getReasonMessage(game.reason) }}
              </div>
            </div>
          </div>
          <div class="text-nowrap text-center periods-block">
            <small class="text-white">{{ game.Periods }}</small>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "StageGames",
  props: ["games", "dataSport"],
  data() {
    return {
      svgPath: require("@/assets/img/info-circle.svg"),
    };
  },
  methods: {
    getReasonMessage(reason) {
      switch (reason) {
        case 1:
          return "Вся техника одной из команд уничтожена";
        case 2:
          return "Захват базы";
        case 3:
          return "Время вышло";
        default:
          return "";
      }
    },
  },
};
</script>

<style scoped>
.separator {
  font-size: 17px;
  font-weight: 400;
  padding: 0 2px;
}

.game-time-block {
  max-width: 60px;
  width: 100%;
}

.game-online {
  letter-spacing: 1px;
  font-size: 0.8rem;
}

.state-small {
  font-size: 0.7rem;
}

.periods-block {
  line-height: 12px;
}

.tooltip-container {
  position: relative;
  display: inline-block;
  margin-left: 5px;
}

.tooltip-icon {
  cursor: default; /* Убираем указатель руки */
  color: #ccc;
  font-weight: bold;
}

.tooltip-message {
  visibility: hidden;
  position: absolute;
  background-color: rgba(0, 0, 0, 0.8);
  color: white;
  padding: 5px 10px;
  border-radius: 4px;
  top: -30px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.75rem;
  white-space: nowrap;
  width: max-content;
  z-index: 10;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.2);
}

.tooltip-container:hover .tooltip-message {
  visibility: visible;
}
</style>
